import React from 'react';
import styled from 'styled-components';
import { fontSize, Paragraph } from '../../../components/globals'
import ReadMore from './ReadMore';

const GroupDescription = styled(Paragraph)`
  ${fontSize(1)}
`;

const CardDescription = ({
  description,
  kind,
  meeting_location_note,
  meeting_day,
  meeting_time,
  area,
  campus_label
}) => {
  
  const locationDescription = (meeting_location_note, campus_label) => {
    switch (meeting_location_note) {
      case 'Somewhere Else':
        return ``;
      case "Home":
        return `at a home`;
      case "Workplace":
        return `at a workplace`;
      case "Sandals Church Campus":
        return `at the ${campus_label} Campus`;
      case "Online":
        return `online`;
      default:
        return ``;
    }
  }

  return (
    <>
      {description ? (
        <GroupDescription>
          <ReadMore>{description}</ReadMore>
        </GroupDescription>
      ) : (
        <GroupDescription>
          Community Group ({kind}) meeting{' '}
          {locationDescription(meeting_location_note, campus_label)}{' '}
          on {meeting_day}s at {meeting_time}.
        </GroupDescription>     
      )}
    </>
  );
};
export default CardDescription;