import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Row } from '../../components/globals'
import { SearchButton } from './styles';
import Select from 'react-select';
import { SearchIcon } from "../../components/icons";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress } from 'react-google-places-autocomplete';
import { useAppContext } from '../../context/appContext';

const SearchScreen = () => {
  const {
    setStep,
    setAddress,
    setCoordinates,
    online,
    setOnline,
    resetDefaultFilters,
    setOnlineSelectState,
    onlineSelectState,
    setLocationInputValue,
    locationInputValue,
  } = useAppContext();

  const { t } = useTranslation('common');

  useEffect(() => {
    resetDefaultFilters();
    setOnlineSelectState({ label: 'In Person', value: 'in_person' });

    // eslint-disable-next-line
  }, []);

  const submitForm = async () => {
    if (!online) {
      if (!locationInputValue) {
        alert(t('search_form.empty_location'));
        return;
      }

      setAddress(locationInputValue.label);
      const results = await geocodeByAddress(locationInputValue.label);
      const coor = {
        lat: results[0].geometry.location.lat(),
        long: results[0].geometry.location.lng(),
      };
      setCoordinates(coor);
    }

    setStep(1);
  };

  const handleOnlineSelectChange = (e) => {
    setOnlineSelectState(e);

    if (e.value === 'in_person') {
      setOnline(false);
    } else {
      setOnline(true);
    }
  };

  return (
    <div>
      <Heading>{t('search_form.heading')}</Heading>
      <Row>
        <Select
          value={onlineSelectState}
          onChange={handleOnlineSelectChange}
          options={[
            { label: t('search_form.in_person_tag'), value: 'in_person' },
            { label: t('search_form.online_tag'), value: 'online' },
          ]}
          className="basic-multi-select"
          isSearchable={false}
        />
      </Row>
      <Row style={{ display: online ? 'none' : 'block' }}>
        <GooglePlacesAutocomplete
          selectProps={{
            value: locationInputValue,
            onChange: setLocationInputValue,
            placeholder: t('search_form.address'),
          }}
          autocompletionRequest={{
            bounds: [
              { lat: 33.906207, lng: -117.165249 },
              { lat: 34.080981, lng: -117.644185 },
            ],
            types: ['geocode', 'establishment'],
          }}
        />
      </Row>
      <SearchButton onClick={submitForm}>
        <SearchIcon />
        {t('search_form.search')}
      </SearchButton>
    </div>
  );
};

export default SearchScreen;
