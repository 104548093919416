import styled from 'styled-components';
import { fontSize, Button } from "../../components/globals";

const SubmitButton = styled(Button)`
  padding: 10px 30px;
  ${fontSize(1.5)}

  i {
    ${fontSize(1.3)}
    margin-right: 10px;
  }
`;

const InputLabel = styled.label`
  font-family: ${(props) => props.theme.type.copy};
  margin-bottom: 5px;
  display: block;
  ${fontSize(1)}
`;

export { SubmitButton, InputLabel };
