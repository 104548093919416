import styled from 'styled-components';
import { fontSize } from '../theme';

const Heading = styled.h1`
  font-family: ${(props) => props.theme.type.heading};
  color: ${(props) => props.theme.colors.textPrimary};
  font-weight: 300;
  text-transform: uppercase;
  margin: 20px 0;
  ${fontSize(3)}
`;

const Subheading = styled.h2`
  font-family: ${(props) => props.theme.type.subheading};
  color: ${(props) => props.theme.colors.textPrimary};
  font-weight: 300;
  text-transform: uppercase;
  margin: 20px 0;
  ${fontSize(2)}
`;

const Paragraph = styled.p`
  font-family: ${(props) => props.theme.type.body};
  color: ${(props) => props.theme.colors.textPrimary};
  margin: 10px 0;
  ${fontSize(1)}
`;

export { Heading, Subheading, Paragraph };
