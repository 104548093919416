import React, { useEffect } from 'react';
import { Subheading, Center, Paragraph } from "../../../components/globals";
import { useTranslation } from 'react-i18next';
import { useAppContext } from "../../../context/appContext";

const SuccessScreen = () => {
  const { t } = useTranslation('common');
  const { setStep } = useAppContext();

  useEffect(() => {
    const timer = setTimeout(() => {
      setStep(0);
    }, 6000)

    return () => clearTimeout(timer); 
  }, [setStep])

  return (
    <>
      <Center>
        <Subheading>{t('sign_up_form.success')}</Subheading>
      </Center>
      <Center>
        <Paragraph>{t('sign_up_form.success_message')}</Paragraph>
      </Center>
    </>
  );
};
export default SuccessScreen;
