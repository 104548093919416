import styled from "styled-components";
import { fontSize } from "../theme";

const Row = styled.div`
  margin: 10px 0;
  display: block;
`;

const Label = styled.span`
  font-family: ${(props) => props.theme.type.copy};
  margin-bottom: 5px;
  display: block;
  ${fontSize(1)}
`;
const Center = styled.div`
  text-align: center;
  width: 100%;
`;

export { Row, Label, Center }