export const colors = {
  textPrimary: '#404040',
  backgroundPrimary: '#414141',
  border: '#bfbfbf',
  blue: '#00AEEF',
  red: '#ec1c24',
  white: '#FFFFFF',
  gray: '#EAEAEA',
  darkGray: '#404040',
  lightGray: '#f1f1f1',
};

export const fontSize = (size) => {
  return `
        font-size: ${size * 16 + 1}px;
        font-size: ${size}rem;
    `;
};

export const transition = (property = 'all', time = '0.25s', ease = 'ease') => {
  return `
        transition: ${property} ${time} ${ease};
    `;
};

export const theme = {
  colors: colors,
  button: {
    radius: '10px',
    color: colors.white,
    background: colors.red,
    disabled: '#D5D5D5',
    borderColor: colors.textPrimary,
  },
  type: {
    copy: '"Trade", sans-serif',
    subheading: '"Trade", sans-serif',
    heading: '"SandalsType", sans-serif',
    body: '"Freight", sans-serif',
  },
  radius: '4px',
  modal: {
    background: '#FFFFFF',
    border: '#BFBFBF',
  },
};
