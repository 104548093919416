import React from 'react';
import { Wrapper } from '../styles';
import { Modal, Heading, Paragraph, Center } from '../../components/globals'
import { useTranslation } from 'react-i18next';
import { OfflineIcon } from "../../components/icons";

const Offline = () => {
  const { t } = useTranslation('common');
  return (
    <Wrapper>
      <Modal>
        <Heading>{t('search_form.heading')}</Heading>
        <Center>
          <OfflineIcon />
        </Center>
        <Paragraph>
          The group finder is unavailable at this time, please check back later!
        </Paragraph>
      </Modal>
    </Wrapper>
  );
};
export default Offline;
