import React from 'react';

export const DownArrow = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="15.37"
      height="8.76"
      viewBox="0 0 15.37 8.76"
    >
      <path
        d="M14,1.11,8.71,6.38a1,1,0,0,1-1.42,0L2,1.11a1,1,0,0,0-1.42,0h0a1,1,0,0,0,0,1.42L7.29,9.28a1,1,0,0,0,1.42,0l6.68-6.75a1,1,0,0,0,0-1.42h0A1,1,0,0,0,14,1.11Z"
        transform="translate(-0.32 -0.82)"
      />
    </svg>
  );
};
