import React, { useState, useContext } from 'react';

const defaultDays = [
  { day: 'monday', active: false, abbreviation: 'm' },
  { day: 'tuesday', active: false, abbreviation: 't' },
  { day: 'wednesday', active: false, abbreviation: 'w' },
  { day: 'thursday', active: false, abbreviation: 'th' },
  { day: 'friday', active: false, abbreviation: 'f' },
  { day: 'saturday', active: false, abbreviation: 'sa' },
  { day: 'sunday', active: false, abbreviation: 'su' },
];

const defaultLocationInputValue = { label: 'In Person', value: 'in_person' };

const defaultContactFormValues = {
  name: '',
  phoneNumber: '',
  email: '',
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [type, setType] = useState({});
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState({ lat: null, long: null });
  const [days, setDays] = useState(defaultDays);
  const [childcare, setChildcare] = useState(false);
  const [online, setOnline] = useState(false);
  const [groupId, setGroupId] = useState({});
  const [step, setStep] = useState(0);
  const [onlineSelectState, setOnlineSelectState] = useState(false);
  const [locationInputValue, setLocationInputValue] = useState(null);
  const [groups, setGroups] = useState([]);
  const [campusOptions, setCampusOptions] = useState([]);
  const [campusSelectState, setCampusSelectState] = useState(null);
  const [contactFormValues, setContactFormValues] = useState(defaultContactFormValues);
  const [offlineMode, setOfflineMode] = useState(false);

  const resetDefaultFilters = () => {
    setOnline(false);
    setType({});
    setDays(defaultDays);
    setLocationInputValue(null);
    setCampusSelectState(null);
    setContactFormValues(defaultContactFormValues);
  };

  return (
    <AppContext.Provider
      value={{
        address,
        campusSelectState,
        campusOptions,
        childcare,
        contactFormValues,
        coordinates,
        days,
        groupId,
        groups,
        locationInputValue,
        offlineMode,
        online,
        onlineSelectState,
        step,
        type,
        resetDefaultFilters,
        setAddress,
        setCampusOptions,
        setCampusSelectState,
        setContactFormValues,
        setCoordinates,
        setDays,
        setGroupId,
        setGroups,
        setLocationInputValue,
        setOfflineMode,
        setOnline,
        setOnlineSelectState,
        setStep,
        setType,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, AppContext };
