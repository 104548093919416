import styled from 'styled-components';
import { Heading } from '../../typography';

const ModalWrapper = styled.div`
  padding: 20px;
  border-radius: ${(props) => props.theme.radius};
  border: solid 1px ${(props) => props.theme.modal.border};
  background: ${(props) => props.theme.modal.background};
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  margin: 0 auto;

  ${Heading} {
    text-align: center;
    margin: 0 0 10px;
  }
`;

const ModalInner = styled.div`
  margin: 0 20px;
  width: 100%;
`;

export { ModalWrapper, ModalInner };
