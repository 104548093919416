import React from 'react';
import { Modal } from "../components/globals";
import { Wrapper, BackButton } from './styles'
import SearchScreen from './search';
import List from './list';
import Connect from './connect';
import { useAppContext } from '../context/appContext';
import { LeftChevron } from '../components/icons'
import useAsyncEffect from 'use-async-effect';
import { getCampuses } from '../api';
import Offline from './offline';

const GroupFinder = () => {
  const { setStep, step, setCampusOptions, offlineMode, setOfflineMode } =
    useAppContext();

  useAsyncEffect(async () => {
    const campuses = await getCampuses();

    if (campuses) {
      setCampusOptions(campuses);
    } else {
      setOfflineMode(true);
    }
    
  }, []);

  const backStep = () => {
    setStep(step - 1);
  };

  if (offlineMode) {
    return <Offline />;
  }

  return (
    <Wrapper>
      {step > 0 && (
        <BackButton onClick={backStep}>
          <LeftChevron />
        </BackButton>
      )}
      <Modal>
        {step === 0 && <SearchScreen />}
        {step === 1 && <List />}
        {step === 2 && <Connect />}
      </Modal>
    </Wrapper>
  );
};

export default GroupFinder;
