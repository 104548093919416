import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAppContext } from "../../../context/appContext";
import { CalendarIcon, TagIcon, MapIcon, OnlineIcon } from "../../../components/icons";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 10px;
  margin-top: 5px;
  flex-wrap: wrap;
`;

const MetaDataParagraph = styled.div`
  font-family: ${(props) => props.theme.type.subheading};
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const MetaDataRow = ({
  distance,
  group: { meeting_day, meeting_time, kind, meeting_location_note, campus_label },
}) => {
  const { t } = useTranslation('common');
  const { online } = useAppContext();

  const meetingLocation = (
    <span>
      <MapIcon />
      {meeting_location_note === 'Sandals Church Campus'
        ? `${campus_label} Campus, `
        : `At a ${meeting_location_note}, `}{' '}
      {`~${distance}mi`}
    </span>
  );

  const somewhereElse = (
    <span>
      <MapIcon />~<span>{distance} {distance > 1 ? 'miles' : 'mile'} away</span> 
    </span>
  );

  return (
    <Wrapper>
      <MetaDataParagraph>
        <CalendarIcon />
        {t(`days.${meeting_day.toLowerCase()}`)}s, {meeting_time}
      </MetaDataParagraph>
      <MetaDataParagraph>
        <TagIcon />
        {kind}
      </MetaDataParagraph>
      {online || meeting_location_note.includes('Online') ? (
        <MetaDataParagraph>
          <OnlineIcon />
          Online
        </MetaDataParagraph>
      ) : (
        <MetaDataParagraph>
          {meeting_location_note === 'Somewhere Else'
            ? somewhereElse
            : meetingLocation}
        </MetaDataParagraph>
      )}
    </Wrapper>
  );
};
export default MetaDataRow;
