import React from 'react';
import { fontSize, Button, Subheading, Paragraph } from "../../../components/globals";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserPlusIcon } from '../../../components/icons'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  background: ${(props) => props.theme.colors.gray};
  border-radius: ${(props) => props.theme.radius};
  margin-bottom: 15px;
  text-align: center;
`;

const SearchButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  margin: 0 auto;
  ${fontSize(1.5)}

  i {
    ${fontSize(1.3)}
    margin-right: 10px;
  }
`;

const Link = styled.a`
  margin-top: 5px;
  color: red;
  cursor: pointer;
  ${fontSize(0.8)}
  &:hover {
    filter: brightness(0.9);
  }
`;

const SmallText = styled(Paragraph)`
  ${fontSize(0.8)}
  margin: 5px 0px;
`;

const NotFoundHeading = styled(Subheading)`
  margin: -2px 0px;
`;

const NotFoundSubheading = styled(Paragraph)`
  padding: 10px;
`;

const ButtonLink = styled.a`
  margin-top: 10px;
  text-decoration: none;
`;
const FooterText = styled.div`
  padding-top: 20px;
`;

const NotFound = () => {
  const { t } = useTranslation('common');

  return (
    <ContentWrapper>
      <NotFoundHeading>{t('search_form.not_found')}</NotFoundHeading>
      <NotFoundSubheading>
        {t('search_form.not_found_group')}
      </NotFoundSubheading>
      <ButtonLink href="http://move.sc/startagroup">
        <SearchButton>
          <UserPlusIcon />
          {t('search_form.start_group')}
        </SearchButton>
      </ButtonLink>
      <FooterText>
        <SmallText>
          {t('search_form.got_questions')}{' '}
          <Link href="https://sandalschurch.ccbchurch.com/goto/forms/1804/responses/new">
            {t('search_form.send_note')}
          </Link>
        </SmallText>
      </FooterText>
    </ContentWrapper>
  );
};
export default NotFound;
