import styled from 'styled-components';
import { fontSize } from "../components/globals/theme";

const Label = styled.span`
  font-family: ${(props) => props.theme.type.copy};
  margin-bottom: 5px;
  display: block;
  ${fontSize(1)}
`;

const BackButton = styled.button`
  padding: 5px 10px 20px;
  transform: translateY(-30px);
  left: 25px;
  top: 72px;
  position: absolute;
  background-color: transparent;
  font-family: ${(props) => props.theme.type.subheading};
  text-decoration: underline;
  color: #000;
  border-radius: ${(props) => props.theme.radius};
  cursor: pointer;
  outline: 0;
  border: solid 2px var(--color__base--bw);
  transition: all 0.25s ease;
  z-index: 10;
  ${fontSize(2)}
`;

const Wrapper = styled.div`
  max-width: 500px;
  margin: 20px auto;
  padding-top: 20px;
  position: relative;

  & > div {
    position: relative;
    z-index: 2;
  }
`;

const GroupDescription = styled.div`
  background: ${(props) => props.theme.colors.gray};
  border: solid 2px ${(props) => props.theme.colors.border};
  border-radius: ${(props) => props.theme.radius};
  padding: 10px;

  h3 {
    font-family: ${(props) => props.theme.type.subheading};
    color: ${(props) => props.theme.colors.textPrimary};
    margin: 0 0 5px 0;
    text-align: center;
    ${fontSize(1)}
  }
`;

export { Label, BackButton, Wrapper, GroupDescription };
