import React, { useState } from 'react';
import { Subheading, Paragraph, Input, Center, Row } from "../../components/globals";
import { GroupDescription } from '../styles';
import { InputLabel, SubmitButton } from './styles';
import { useAppContext } from '../../context/appContext';
import { signUp } from "../../api";
import { useTranslation } from 'react-i18next';
import { PaperAirplaneIcon } from "../../components/icons";
import Select from 'react-select';
import SuccessScreen from "./components/SuccessScreen";
import ClipLoader from 'react-spinners/ClipLoader';

const Connect = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation('common');

  const {
    campusOptions,
    campusSelectState,
    contactFormValues,
    groupId,
    setCampusSelectState,
    setContactFormValues,
  } = useAppContext();

  const submitForm = async (e) => {
    e.preventDefault();
  
    if (!contactFormValues.name) {
      alert('Please provide your full name');
      return;
    }

    if (!contactFormValues.phoneNumber) {
      alert('Please provide a valid phone number');
      return;
    }

    if (!contactFormValues.email) {
      alert('Please provide a valid email address');
      return;
    }

    if (!campusSelectState) {
      alert('Please provide a campus');
      return;
    }

    setLoading(true);

    const formData = {
      group: groupId,
      name: contactFormValues.name,
      phone: contactFormValues.phoneNumber,
      email: contactFormValues.email,
      campus: campusSelectState.value,
    };

    const data = await signUp(formData);
  
    if (data.status === 200) {
      setSuccess(true);
    } else {
      setLoading(false);
      alert(data.data.error.message)
      return
    }
  };

  const handleChange = (e) => {
    setContactFormValues({
      ...contactFormValues,
      [e.target.name]: e.target.value,
    });
  };

  if (success) {
    return <SuccessScreen />;
  }
 
  return (
    <>
      <Center>
        <Subheading>{t('sign_up_form.heading')}</Subheading>
      </Center>
      <GroupDescription>
        <h3>{t('sign_up_form.group')}:</h3>
        <Center>
          <Paragraph>
            {groupId.name}
            <br />
            {t(`days.${groupId.meeting_day.toLowerCase()}`)} : {groupId.meeting_time}{' '}
          </Paragraph>
        </Center>
      </GroupDescription>
      <Paragraph>{t('sign_up_form.description')}</Paragraph>
      <Row>
        <Input
          type="text"
          name="name"
          value={contactFormValues.name}
          onChange={handleChange}
          label={t('sign_up_form.name')}
          placeholder=""
        />
      </Row>
      <Row>
        <Input
          name="phoneNumber"
          value={contactFormValues.phoneNumber}
          onChange={handleChange}
          label={t('sign_up_form.phone')}
          placeholder=""
        />
      </Row>
      <Row>
        <Input
          name="email"
          value={contactFormValues.email}
          onChange={handleChange}
          label={t('sign_up_form.email')}
          placeholder=""
        />
      </Row>
      <Row>
        <InputLabel htmlFor="campus-select">Where do you attend?</InputLabel>
        <Select
          id="campus-select"
          value={campusSelectState}
          onChange={setCampusSelectState}
          options={campusOptions}
          className="basic-multi-select"
          isSearchable={false}
          placeholder="Choose a campus"
        />
      </Row>
      <Center>
        <SubmitButton disabled={loading} onClick={submitForm}>
          {(loading && !success) ? (
            <ClipLoader speedMultiplier={0.7} />
          ) : (
            <>
              <PaperAirplaneIcon />
              {t('sign_up_form.submit')}
            </>
          )}
        </SubmitButton>
      </Center>
    </>
  );
};

export default Connect;
