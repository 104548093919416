import React from 'react';
import BaseTheme from "./components/globals/BaseTheme";
import GroupFinder from './screens';

function App() {
  return (
    <BaseTheme>
      <GroupFinder />
    </BaseTheme>
  );
}

export default App;
