import axios from 'axios';
import qs from 'qs';

const api = axios.create({
  timeout: 30000,
});

export const getGroups = async (
  type,
  location,
  days,
  childcare,
  online
) => {
  var daysList = '';

  for (let day of days) {
    if (day.active === true) {
      daysList += `${day.day},`;
    }
  }

  const params = {
    type,
    location: online ? 'online' : location.label,
    days: daysList,
    childcare,
  };

  const { data } = await api.get(
    process.env.REACT_APP_SANDALS_API + '/groups/community/nearby',
    { params }
  );

  return data;
};

export const signUp = async ({ group, name, phone, email, campus }) => {
  try {
    const params = {
      name,
      phone,
      email,
      campus,
    };

    const response = await api.post(
      `${process.env.REACT_APP_SANDALS_API}/groups/join/${group.group_id}`,
      qs.stringify(params)
    );

    return response;

  } catch (error) {
    return error.response;
  }
};

export const getCampuses = async () => {
  try {
    const { data } = await api.get(
      `${process.env.REACT_APP_SANDALS_API}/campus/sorted`
    );

    const options = data.map((campus) => {
      return { label: campus.name, value: campus.id };
    });

    return options;

  } catch (error) {

    return null;
  }
};
