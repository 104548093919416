import styled from 'styled-components';
import { Subheading, fontSize } from "../../components/globals";

const DaysWrapper = styled.div`
  text-align: center;

  display: flex;
  flex-direction: column;
  div {
    margin-bottom: 5px;
  }
  button {
    margin: 0 5px;
  }
`;

const GroupCard = styled.div`
  padding: 10px;
  background: ${(props) => props.theme.colors.gray};
  border-radius: ${(props) => props.theme.radius};
  margin-bottom: 20px;

  i {
    margin-right: 5px;
  }
`;

const GroupHeading = styled(Subheading)`
  margin: 0;
  ${fontSize(1.5)}
`;

const Childcare = styled.span`
  font-family: ${(props) => props.theme.type.subheading};
  margin-left: 25px;
  position: relative;

  &::after {
    content: '';
    width: 5px;
    height: 5px;
    background: ${(props) => props.theme.colors.red};
    display: block;
    border-radius: 50%;
    position: absolute;
    left: 0;
    transform: translate(-10px, 7px);
    top: 0;
  }
`;

const GroupList = styled.div`
  transform: translateY(20px);
  opacity: 0;
  max-height: 500px;
  min-height: 500px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-y: scroll;
`;

const SelectWrapper = styled.div`
  width: 100%;
  display: flex;

  div:nth-child(1) {
    width: 100%;
  }
`;
const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export {
  GroupCard,
  GroupHeading,
  Childcare,
  GroupList,
  SelectWrapper,
  DaysWrapper,
  FilterWrapper,
};
