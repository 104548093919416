import styled from 'styled-components';
import { fontSize, transition } from '../../theme';

const SelectWrapper = styled.div`
  display: inline-block;
  width: auto;
  position: relative;
`;

const SelectOptionsWrapper = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: ${(props) => props.theme.radius};
  overflow: hidden;
  animation-delay: 0.25s;
  transform: translateY(5px);
  z-index: 10;
  max-height: 220px;
  overflow-y: scroll;
  background: ${(props) => props.theme.colors.white};
  ${transition()}

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  display: ${(props) => (props.open ? 'block' : 'none')};
`;

const SelectFieldOption = styled.button`
  display: block;
  appearance: none;
  border: 0;
  padding: 8px 10px;
  width: 100%;
  font-family: ${(props) => props.theme.type.copy};
  text-align: left;
  cursor: pointer;
  background: ${(props) => props.theme.colors.white};
  border-bottom: solid 1px ${(props) => props.theme.colors.border};
  ${fontSize(1)}
  ${transition()}

  &:hover {
    background: ${(props) => props.theme.colors.lightGray};
  }
  background: ${(props) => props.selected && props.theme.colors.lightGray};
`;

const SelectField = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: ${(props) => props.theme.radius};
  box-sizing: border-box;
  appearance: none;
  background: ${(props) => props.theme.colors.white};
  padding: 8px 10px;
  font-family: ${(props) => props.theme.type.copy};
  cursor: pointer;
  min-width: 150px;
  width: 100%;
  text-align: left;
  ${fontSize(1)}

  svg {
    ${transition()}
    margin-left:  10px;
    transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  &.long {
    ${fontSize(0.8)}
  }
`;

export { SelectWrapper, SelectField, SelectFieldOption, SelectOptionsWrapper };
