import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import {
  GroupCard,
  GroupHeading,
  Childcare,
  GroupList,
  SelectWrapper,
  DaysWrapper,
  FilterWrapper,
} from './styles';
import { Heading, Row, Center, Button } from '../../components/globals'
import CircularBtn from './components/CircularBtn';
import { useTranslation } from 'react-i18next';
import { getGroups } from "../../api";
import Types from '../group-types';
import MetaDataRow from './components/MetaDataRow';
import CardDescription from './components/CardDescription';
import NotFound from './components/NotFound';
import { useAppContext } from '../../context/appContext';
import ClipLoader from 'react-spinners/ClipLoader';
import { limitResultsByDistance } from "../../utils";

const google = window.google;

const List = () => {
  const { t } = useTranslation('common');

  const groupListRef = useRef();
  const loadingRef = useRef();
  const tl = gsap.timeline();

  const {
    address,
    childcare,
    coordinates,
    days,
    groups,
    online,
    type,
    setCoordinates,
    setDays,
    setGroupId,
    setGroups,
    setStep,
    locationInputValue,
    campusOptions
  } = useAppContext();

  useEffect(() => {
    async function fetchData() {

      if ((!coordinates.lat || !coordinates.long) && !online) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: address }, function (results, status) {

          if (status === 'OK') {
            const coor = {
              lat: results[0].geometry.location.lat(),
              long: results[0].geometry.location.lng(),
            };

            if (!coor.lat || !coor.long) {
              alert(
                'There was a problem, please try again or contact web@sandalschurch.com'
              );
              return;
            }

            setCoordinates(coor);
          } else {
            alert(
              'There was a problem, please try again or contact web@sandalschurch.com'
            );
            return;
          }

        });
      }

      const filteredGroups = await getGroups(
        type.value,
        locationInputValue,
        days,
        childcare,
        online
      );

      setGroups(limitResultsByDistance(filteredGroups, 100));

      tl.to(loadingRef.current, {
        opacity: 0,
        display: 'none'
      });
      tl.to(groupListRef.current, { opacity: 1, y: 0 });
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childcare, days, type]);

  const groupPicked = (group) => {
    setGroupId(group);
    setStep(2);
  };

  const renderGroup = (props) => {

    const { label: campus_label } = campusOptions.find(
      (campus) => props.group.campus === campus.value
    );
    
    props.group.campus_label = campus_label;

    const {
      group: { childcare, meeting_day, meeting_time, group_id, name },
    } = props;
    const n = name.replace('Community Group: ', '');
    return (
      <GroupCard key={group_id}>
        <GroupHeading>{n}</GroupHeading>
        <MetaDataRow {...props} />
        <CardDescription {...props.group} />
        <Row>
          <Button
            onClick={() =>
              groupPicked({ name, meeting_day, meeting_time, group_id })
            }
          >
            {t('search_form.connect')}
          </Button>
          {childcare && <Childcare>{t('search_form.children')}</Childcare>}
        </Row>
      </GroupCard>
    );
  };

  const toggleDay = (e) => {
    e.preventDefault();
    const clickedDay = e.target.value;

    const newState = days.map((day) => {
      
      if (day.day === clickedDay) {
        return { ...day, active: !day.active };
      }
      return day;
    });

    setDays(newState);
  };

  return (
    <>
      <Heading>{t('search_form.heading')}</Heading>
      <Center ref={loadingRef}>
        <ClipLoader size={50} speedMultiplier={0.7} />
      </Center>
      <GroupList ref={groupListRef}>
        <FilterWrapper>
          <Row>
            <SelectWrapper>
              <Types />
            </SelectWrapper>
          </Row>
          <Row>
            <DaysWrapper>
              <div>
                <small>{t('search_form.days_filter')}</small>
              </div>
              <div>
                {days.map(({ day, active, abbreviation }) => (
                  <CircularBtn
                    key={day}
                    active={active}
                    value={day}
                    onClick={toggleDay}
                  >
                    {t(`search_form.days.${abbreviation}`)}
                  </CircularBtn>
                ))}
              </div>
            </DaysWrapper>
          </Row>
        </FilterWrapper>
        {groups.map(renderGroup)}
        {groups.length < 4 && <NotFound />}
      </GroupList>
    </>
  );
};

export default List;