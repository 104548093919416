import { theme } from "../../../components/globals";
import React, { useState } from 'react';
import styled from 'styled-components';

const ReadMoreSpan = styled.span`
  color: ${(props) => theme.colors.red};
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }
`;

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  if (!text || text === '') {
    return null;
  }

  if (text.length > 250) {
    return (
      <>
        <>
          {isReadMore ? text.slice(0, 200) : text}
          <ReadMoreSpan onClick={toggleReadMore}>
            {isReadMore && ` ...show more`}
          </ReadMoreSpan>
        </>
        <br />
        <ReadMoreSpan onClick={toggleReadMore}>
          {!isReadMore && `\n show less`}
        </ReadMoreSpan>
      </>
    );
  }

  return <>{text}</>;
};

export default ReadMore;
