import React, { useState, useRef, useEffect } from 'react';
import {
  SelectWrapper,
  SelectField,
  SelectOptionsWrapper,
  SelectFieldOption,
} from './styles';
import { DownArrow } from './icons';
import { InputLabel } from '../../inputs/styles';

const Select = ({
  options,
  fieldLabel,
  label,
  value,
  placeholder,
  onChange,
  defaultVal,
  ...other
}) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {

    if (selectRef.current) {
      selectRef.current.focus();
    }
    
  }, [open]);

  function selectOption(o, e) {
    e.preventDefault();
    setOpen(false);
    onChange(o);
  }

  function toggle(e) {
    e.preventDefault();
    setOpen(!open);
  }

  function renderOptions(o, i) {
    return (
      <SelectFieldOption
        role="tab"
        key={i}
        selected={o.value === value ? true : false}
        value={o.value}
        onClick={(e) => {
          selectOption(o, e);
        }}
      >
        {o.label}
      </SelectFieldOption>
    );
  }

  return (
    <SelectWrapper>
      <InputLabel
        onClick={(e) => {
          toggle(e);
        }}
      >
        {fieldLabel}
      </InputLabel>
      <SelectField
        open={open}
        onClick={(e) => {
          toggle(e);
        }}
        role="combobox"
        value={value ? value : options[0].value}
        className={label.length > 20 && 'long'}
        aria-expanded={open ? 'true' : 'false'}
        {...other}
      >
        {label ? label : options[0].label}
        <DownArrow />
      </SelectField>
      <SelectOptionsWrapper
        role="tablist"
        open={open}
        tabIndex="-1"
        ref={selectRef}
      >
        {options.map(renderOptions)}
      </SelectOptionsWrapper>
    </SelectWrapper>
  );
};

Select.defaultProps = {
  options: [],
  fieldLabel: 'Select an option',
  label: 'Select an option',
  value: null,
  placeholder: 'Select an option',
  onChange: () => {},
};

export { Select };
