import React from 'react';
import { ModalWrapper } from './styles';
import { Heading } from '../../typography';

const Modal = ({ heading, children }) => {
  return (
    <ModalWrapper>
      {heading && <Heading>{heading}</Heading>}
      {children}
    </ModalWrapper>
  );
};

Modal.defaultProps = {
  heading: null,
  children: null,
};

export { Modal };
