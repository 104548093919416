import React, { forwardRef } from 'react';
import { Input as StyledInput, InputWrapper, InputLabel } from './styles';

const Input = forwardRef(
  ({ placeholder, label, onChange, id, type, ...other }, ref) => (
    <InputWrapper>
      <InputLabel htmlFor={id ? id : label}>{label}</InputLabel>
      <StyledInput
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        ref={ref}
        id={id ? id : label}
        role="textbox"
        {...other}
      />
    </InputWrapper>
  )
);

Input.defaultProps = {
  placeholder: 'type',
  type: 'text',
  onChange: () => '',
  id: '',
};

export { Input };
