import React from 'react';
import { useAppContext } from "../../context/appContext";
import { useTranslation } from 'react-i18next';
import { Select } from "../../components/globals";

const Types = () => {
  const { t } = useTranslation('common');
  const types = [
    {
      label: 'All Types',
      value: undefined,
      desc: false,
    },
    {
      label: 'Couples',
      value: 'Couples',
      desc: false,
    },
    {
      label: 'Family',
      value: 'Family',
      desc: false,
    },
    {
      label: 'Marriage',
      value: 'Marriage',
      desc: false,
    },
    {
      label: 'Men',
      value: 'Men',
      desc: false,
    },
    {
      label: 'Mixed',
      value: 'Mixed',
      desc: false,
    },
    {
      label: 'Spanish Family',
      value: 'Spanish Family',
      desc: false,
    },
    {
      label: 'Spanish Mixed',
      value: 'Spanish Mixed',
      desc: false,
    },
    {
      label: 'Women',
      value: 'Women',
      desc: false,
    },
    {
      label: 'Young Adult Men',
      value: 'Young Adult Men',
      desc: false,
    },
    {
      label: 'Young Adult Mixed',
      value: 'Young Adult Mixed',
      desc: false,
    },
    {
      label: 'Young Adult Women',
      value: 'Young Adult Women',
      desc: false,
    },
  ];

  const { type, setType } = useAppContext();

  const changeType = (t) => {
    setType(t);
  };

  return (
    <Select
      fieldLabel={t('search_form.type')}
      options={types}
      value={type.value}
      label={type.label}
      onChange={changeType}
    />
  );
};

export default Types;
