import styled from 'styled-components';
import React from 'react';
import { fontSize } from "../../../components/globals";

const Button = styled.button`
  font-family: ${(props) => props.theme.type.copy};
  text-transform: uppercase;
  text-transform: none;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? props.theme.button.background : props.theme.button.disabled};
  color: ${(props) => props.theme.button.color};
  cursor: pointer;
  ${fontSize(1)}

  &:hover {
    filter: brightness(0.9);
  }
`;

const CircularBtn = ({ onClick, active, value, children }) => {
  return (
    <Button onClick={onClick} active={active} value={value}>
      {children}
    </Button>
  );
};
export default CircularBtn;
