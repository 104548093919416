import styled from 'styled-components';
import { fontSize, transition } from '../theme';

const Button = styled.button`
  display: inline-block;
  padding: 6px 20px;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  text-align: center;
  background: ${(props) => props.theme.button.background};
  font-family: ${(props) => props.theme.type.copy};
  color: ${(props) => props.theme.button.color};
  border: 0;
  border-radius: ${(props) => props.theme.button.radius};
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
  ${({ disabled, theme }) =>
    disabled &&
    `
    background: ${theme.button.disabled};
    cursor: auto;
  `}
  ${fontSize(1)}
  ${transition()}

  //Hover effect element
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: ${(props) => props.theme.button.radius};
    width: 50%;
    opacity: 0;
    transition: width 0.25s, opacity 0.25s;
    margin: auto;
  }

  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.button.color};
    transform: translateY(-2px);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);

    ${({ disabled }) =>
      disabled &&
      `
      transform: translateY(0);
      box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
    `}
  }

  &:hover::before,
  &:focus::before {
    width: 100%;
    opacity: 1;
    ${({ disabled }) =>
      disabled &&
      `
      width: 0;
      opacity: 0;
    `}
  }
`;

export { Button };
