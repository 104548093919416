import styled from 'styled-components';
import { fontSize, transition } from '../theme';

const Input = styled.input`
  display: block;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.border};
  outline: none;
  box-sizing: border-box;
  appearance: none;
  overflow: hidden;
  margin: 0;
  font-family: ${(props) => props.theme.type.copy};
  padding: 8px 10px 8px 13px;
  border-radius: ${(props) => props.theme.radius};
  ${fontSize(1.3)}
  ${transition()}

  &:active,
  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.8);
  }

  &.missing {
    border: 1px solid ${(props) => props.theme.colors.red};
  }
`;

const InputWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
`;

const InputLabel = styled.label`
  font-family: ${(props) => props.theme.type.copy};
  margin-bottom: 5px;
  display: block;
  ${fontSize(1)}
`;

export { Input, InputWrapper, InputLabel };
