import styled from 'styled-components';
import { Button, fontSize } from "../../components/globals";

const SearchButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  margin: 0 auto;
  ${fontSize(1.5)}

  i {
    ${fontSize(1.3)}
    margin-right: 10px;
  }
`;

export { SearchButton };
